<template>
  <PageWithLayout>
    <search
      :tabData="viewModel.tabData"
      :searchDataList.sync="viewModel.searchData[viewModel.tabData.value].searchDataList"
      :searchParams.sync="viewModel.searchParams"
      @onUpdateTab="viewModel.onUpdateTab()"
      @onSearch="viewModel.onSearch()"/>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchData[viewModel.tabData.value].searchParams"
      :dataList="viewModel.searchData[viewModel.tabData.value].dataList"
      :selectedDataList="viewModel.selectedDataList"
      :paginationData="viewModel.searchData[viewModel.tabData.value].paginationData"
      :emptyDesc="viewModel.searchData[viewModel.tabData.value].searchDataList[0].value ? '검색결과가 없습니다' : '회원을 검색해주세요'"
      @onClickRow="(rowId,rowData) => viewModel.onClickRow(rowId,rowData)"
      @onSearch="viewModel.onSearch()">
      <template v-slot:headRight>
        <span class="txt_right">{{ viewModel.searchData[viewModel.tabData.value].responseDate }}</span>
      </template>
    </Board>
    <template v-slot:popup>
      <CsUserByUserDataDetailPopup
        v-if="viewModel.isCsUserByUserDataDetailPopup"
        :detailData="viewModel.detailData"
        @onSearchList="viewModel.onSearch()"
        @getDeliveryData="viewModel.getDeliveryData()"
        @onClickClose="viewModel.onClickCloseCsUserByUserDataDetailPopup()"/>
      <MembershipJoinPopup
        v-if="viewModel.isMembershipJoinPopup"
        :joinData="viewModel.joinModel"
        :isFixedUid="true"
        :membershipTypeList="viewModel.membershipTypeList"
        @onClickClose="viewModel.onClickCloseMembershipJoinPopup()"
        @onClickComplete="joinData => viewModel.onClickCompeletMembershipJoinPopup(joinData)"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
// popup
import CsUserByUserDataDetailPopup from '@/views/cs/user/byuserdata/view/popup/CsUserByUserDataDetailPopup'
import MembershipJoinPopup from '@/views/service/membership/payments/view/popup/MembershipJoinPopup'
// viewModel
import CsUserByUserDataListViewModel from '@/views/cs/user/byuserdata/viewModel/CsUserByUserDataListViewModel'

export default {
  name: 'CsUserByUserDataList',
  components:{
    PageWithLayout,
    Search,
    Board,

    CsUserByUserDataDetailPopup,
    MembershipJoinPopup
  },
  data(){
    return{
      viewModel: new CsUserByUserDataListViewModel(),
    }
  },
  beforeMount(){
    if(this.$route.query.tab && this.$route.query.value){
      this.viewModel.setQuery(this.$route.query);
    }
    if(document.title === 'Firfin Finance Admin') return;
    document.title = 'Firfin Finance Admin';
  },
}
</script>
<style scoped>
/* .board_comm::v-deep .tbl_comm table{min-width:100%;width:auto} */
.board_comm::v-deep .tbl_comm table .txt_tbl{display:block;max-width:200px}
.board_comm::v-deep .tbl_comm table .td_mark .txt_tbl{display:inline-block}
.txt_right{margin-top:17px;color:#999}
</style>